@import "../../assets/sass/variables.scss";

* {
  font-family: "Space Grotesk";
}
.demographic {
  .outer-padding {
    padding: 0;
    height: 100vh;
    display: flex;
    .inside-padding {
      width: 100%;
      padding: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      .content-area {
        margin-left: 0;
        .title {
          width: 300px;
          margin-bottom: 50px;
          h1 {
            font-size: 27px;
            color: $heading-color;
          }
          p {
            font-size: 16px;
            color: $text-color;
          }
        }
        .select-category {
          .btn-dropdown {
            width: 290px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .demographic {
    .outer-padding {
      padding: 0px 60px 60px;
      background-color: $bg-gray-10;
      .inside-padding {
        padding: 100px;
        background-color: white;
        border-radius: 0 0 15px 15px;
        .content-area {
          .title {
            width: 440px;
            margin-bottom: 80px;
            h1 {
              font-size: 35px;
            }
            p {
              font-size: 17px;
            }
          }
          .select-category {
            .btn-dropdown {
              width: 370px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .demographic {
    .outer-padding {
      padding: 60px 60px 60px 0px;
      .inside-padding {
        padding: 100px;
        border-radius: 0 15px 15px 0;
        .content-area {
          margin-left: 100px;
          width: 100%;
          .select-category {
            .btn-dropdown {
              width: 340px;
            }
          }
        }
      }
    }
  }
}
