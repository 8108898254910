@import "../../assets/sass/variables.scss";

.error {
  padding: 0 60px;
  position: relative;
  height: 100vh;
  .brand-logo {
    position: absolute;
    top: 65px;
    svg {
      width: 90px;
    }
  }
  .red-stripe {
    position: fixed;
    left: 31px;
    top: 0;
    right: 31px;
    width: auto;
    height: 35px;
    background-color: $red;
    border-radius: 0 0 16px 16px;
  }
  .green-stripe {
    position: fixed;
    left: 31px;
    top: 0;
    right: 31px;
    width: auto;
    height: 35px;
    background-color: $primary-color;
    border-radius: 0 0 16px 16px;
  }
  .icon-watermark {
    display: none;
  }
  .content-area {
    display: flex;
    align-items: center;
    height: 90vh;
    max-width: 100%;
    .title {
      margin-bottom: 60px;
      h1 {
        font-size: 25px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 768px) {
  .error {
    padding: 0 110px;
    position: relative;
    .brand-logo {
      top: 95px;
      svg {
        width: auto !important;
      }
    }

    .red-stripe {
      height: 35px;
      left: 65px;
      right: 65px;
    }
    .green-stripe {
      height: 35px;
      left: 65px;
      right: 65px;
    }
    .icon-watermark {
      display: block;
      position: absolute;
      bottom: 0;
      right: 65px;
      svg {
        width: 242px;
        height: auto;
      }
    }
    .content-area {
      height: 100vh;
      .title {
        h1 {
          font-size: 35px;
        }
        p {
          font-size: 26px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .error {
    .brand-logo {
      top: 65px;
      right: 65px;
    }
    .red-stripe {
      position: fixed;
      left: 0;
      top: 50px;
      bottom: 50px;
      width: 40px;
      border-radius: 0 16px 16px 0;
      height: auto;
    }
    .green-stripe {
      position: fixed;
      left: 0;
      top: 50px;
      bottom: 50px;
      width: 40px;
      border-radius: 0 16px 16px 0;
      height: auto;
    }
    .content-area {
      margin-left: 114px;
    }
    .icon-watermark {
      svg {
        width: 380px;
      }
    }
  }
}
