@import "../../assets/sass/variables.scss";
.priorities {
  height: auto;
  background-color: $bg-gray-10;
  padding: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title-section {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    h1 {
      font-size: 27px;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
  .priorities-blocks {
    width: 91%;
    display: flex;
    align-items: center;
    .priorities-inside {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: $secondary-color;
      color: #fff;
      height: 35px;
      padding: 6px 10px;
      border-radius: 6px;
      &:hover {
        background-color: $primary-color;
        color: $white;
        .left {
          .move-icon {
            i {
              &::before {
                color: $white;
              }
            }
          }
        }
        .emoji {
          ul {
            li {
              a {
                color: $white;
                opacity: 0.5;
              }
              .very-sad {
                color: $white;
                opacity: 1;
              }
              .sad {
                color: $white;
                opacity: 1;
              }
              .happy {
                color: $white;
                opacity: 1;
              }
              .very-happy {
                color: $white;
                opacity: 1;
              }
            }
          }
        }
      }
      .left {
        display: inline-flex;
        align-items: center;
        width: 100%;
        .move-icon {
          display: inline-flex;
          i {
            &::before {
              color: #69696c;
              font-size: 20px;
            }
          }
        }
        .info {
          display: inline-flex;
          align-items: center;
          i {
            &::before {
              font-size: 20px;
            }
          }
          h6 {
            margin: 0;
            padding: 0;
            color: #fff;
            margin-left: 8px;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
      .emoji {
        display: inline-flex;
        margin-right: auto;
        ul {
          list-style: none;
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          gap: 4px;
          li {
            a {
              color: $gray-50;
              i {
                &::before {
                  font-size: 18px;
                }
              }
            }
            .very-sad {
              color: $very-sad;
            }
            .sad {
              color: $sad;
            }
            .happy {
              color: $happy;
            }
            .very-happy {
              color: $very-happy;
            }
          }
        }
      }
    }
  }
  .index {
    width: 9%;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .btn-clarify {
    margin-top: 20px;
    display: inline-flex;
    i {
      &::before {
        font-size: 20px;
      }
    }
    a {
      font-size: 16px;
      color: $secondary-color;
      text-decoration: none;
      line-height: 1.2;
    }
  }
  a.btn-finish {
    width: 100%;
    height: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #4bef0f !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    i {
      margin-left: 10px;
      &::before {
        font-size: 25px;
      }
    }
  }
}

@media (min-width: 768px) {
  .priorities {
    height: 100vh;
    padding: 60px 60px 30px;
    .priorities-blocks {
      width: 95%;

      .priorities-inside {
        height: 40px;
        .left {
          width: 230px;
          .move-icon {
            i {
              &::before {
                font-size: 24px;
              }
            }
          }
          .info {
            i {
              &::before {
                font-size: 26px;
              }
            }
            h6 {
              font-size: 16px;
            }
          }
        }
        .emoji {
          ul {
            li {
              a {
                i {
                  &::before {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .index {
      width: 5%;
      font-size: 17px;
    }

    a.btn-finish {
      width: 300px;
      position: relative;
      padding: 10px 15px;
      border-radius: 6px;
      margin: 0 auto;
    }
  }
}

@media (min-width: 992px) {
  .priorities {
    .priorities-blocks {
      .priorities-inside {
        height: 36px;
        .left {
          width: 230px;
          .move-icon {
            i {
              &::before {
                font-size: 24px;
              }
            }
          }
          .info {
            i {
              &::before {
                font-size: 20px;
              }
            }
            h6 {
              font-size: 16px;
            }
          }
        }
        .emoji {
          ul {
            li {
              a {
                i {
                  &::before {
                    font-size: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .index {
      font-size: 18px;
    }
    a.btn-clarify {
      padding: 10px 20px;
      background-color: $secondary-color;
      text-decoration: none;
      color: $white;
      border-radius: 6px;
      display: inline-flex;
      align-items: center;
      &:hover {
        background-color: $primary-color;
      }
      i {
        &::before {
          color: $white;
        }
      }
    }
  }
}
@media (width: 1024px) {
  .priorities {
    height: auto;
  }
}
@media (min-width: 1200px) {
  .priorities {
    height: 100vh;
    padding: 50px;
    .title-section {
      width: 350px;
    }
    a.btn-finish {
      background-color: #d3d3d8 !important;
      width: 100%;
      &:hover {
        background-color: $primary-color !important;
      }
    }
  }
}

@media (min-width: 1400px) {
  .priorities {
    padding: 100px 100px 45px;
  }
}