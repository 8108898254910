@import "../../assets/sass/variables.scss";

.indicator {
  .indicator-slider {
    margin-left: 5px;
    margin-right: 5px;
    display: block;
  }
  .indicator-rate {
    display: none;
  }
  .section-padding {
    width: 100%;
    .slick-slider {
      height: 100%;
      overflow: hidden;
      .main-info {
        padding: 0 20px;
        background-color: $bg-gray-10;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .title-bar {
          margin-bottom: 0;
          .top-icon {
            display: inline-flex;
            i {
              &::before {
                font-size: 44px;
                color: $primary-color;
              }
            }
          }
          h1 {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -1px;
            margin: 10px 0;
          }
          p {
            font-size: 14px;
            color: $text-color;
          }
        }
        .slider-progress-bar {
          margin-bottom: 0;
          label {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0;
          }
          .ant-slider {
            margin: 0;
            padding: 0;
            margin-top: 20px;
            .ant-slider-rail {
              border: 3px solid #e3e3e4;
              border-radius: 100px;
            }
            .ant-slider-track {
              border-radius: 100px;
            }
            .ant-slider-handle {
              width: 20px;
              height: 20px;
              margin-top: -7px;
              border: solid 3px #28282d;
            }
          }
          .range-slider {
            position: relative;
            .inner {
              display: flex;
              z-index: 100;
              top: 0;
              width: 100%;
              column-gap: 4px;
              direction: rtl;
              .active {
                background-color: $primary-color;
              }
              div {
                flex: 1;
                height: 10px;
                background-color: #cdcdce;
                border-radius: 100px;
                text-indent: -100px;
                position: relative;
                z-index: 2;
                cursor: pointer;
                transition: all 0.5s ease-in-out;
                &:hover {
                  background-color: #fd6d22;
                }
                &:hover ~ div {
                  background-color: #fd6d22;
                }
              }
            }
          }
          .rating {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            ul {
              li {
                a {
                  color: $gray-30;
                  i {
                    &::before {
                      font-size: 40px;
                    }
                  }
                }
                .very-sad {
                  color: $very-sad;
                }
                .sad {
                  color: $sad;
                }
                .happy {
                  color: $happy;
                }
                .very-happy {
                  color: $very-happy;
                }
              }
              li:nth-child(1) a:hover {
                color: $very-sad;
              }
              li:nth-child(2) a:hover {
                color: $sad;
              }
              li:nth-child(3) a:hover {
                color: $happy;
              }
              li:nth-child(4) a:hover {
                color: $very-happy;
              }
            }
          }
        }
      }
    }
  }
  .icons-bar {
    padding: 17px 20px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .list-group {
      justify-content: space-between;
      .list-group-item {
        padding: 6px 8px;
        width: 28px;
        height: 28px;
        border-radius: 24px;
        border: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        a {
          text-decoration: none;
          color: $gray-20;
          display: flex;
          i {
            &::before {
              font-size: 20px;
            }
          }
        }
      }
    }
    .list-group-item.selected.active {
      background: $primary-color;
      a {
        color: $white;
      }
    }
    .list-group-item.selected {
      background: $primary-color;
      a {
        color: $white;
      }
    }
    .list-group-item.active {
      background: transparent;
      a {
        color: $primary-color;
        &::before {
          color: $primary-color;
        }
      }
    }
  }
  main {
    height: 100vh;
    overflow: hidden;
  }
  .arrow-bar {
    padding: 20px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    ul {
      li {
        a {
          color: $gray-30;
          i {
            &::before {
              font-size: 20px;
            }
          }
        }
        .active {
          color: $secondary-color;
        }
      }
      li:first-child {
        transform: rotate(-90deg);
      }
      li:last-child {
        transform: rotate(-90deg);
      }
    }
  }
}

@media (min-width: 430px) {
  .indicator {
    .section-padding {
      .slick-slider {
        .main-info {
          padding: 0 30px;
          .title-bar {
            margin-bottom: 30px;
            .top-icon {
              display: inline-flex;
              i {
                &::before {
                  font-size: 54px;
                  color: $primary-color;
                }
              }
            }
            h1 {
              font-size: 27px;
              margin: 15px 0;
            }
            p {
              font-size: 16px;
            }
          }
          .slider-progress-bar {
            margin-bottom: 0;
            label {
              font-size: 16px;
              margin-bottom: 15px;
            }
            .ant-slider {
              margin: 0;
              padding: 0;
              margin-top: 20px;
            }
            .range-slider {
              position: relative;
              .inner {
                display: flex;
                z-index: 100;
                top: 0;
                width: 100%;
                column-gap: 4px;
                direction: rtl;
                .active {
                  background-color: $primary-color;
                }
                div {
                  flex: 1;
                  height: 10px;
                  background-color: #cdcdce;
                  border-radius: 100px;
                  text-indent: -100px;
                  position: relative;
                  z-index: 2;
                  cursor: pointer;
                  transition: all 0.5s ease-in-out;
                  &:hover {
                    background-color: #fd6d22;
                  }
                  &:hover ~ div {
                    background-color: #fd6d22;
                  }
                }
              }
            }
            .rating {
              display: flex;
              flex-direction: column;
              margin-top: 50px;
              ul {
                li {
                  a {
                    color: $gray-30;
                    i {
                      &::before {
                        font-size: 40px;
                      }
                    }
                  }
                  .very-sad {
                    color: $very-sad;
                  }
                  .sad {
                    color: $sad;
                  }
                  .happy {
                    color: $happy;
                  }
                  .very-happy {
                    color: $very-happy;
                  }
                }
                li:nth-child(1) a:hover {
                  color: $very-sad;
                }
                li:nth-child(2) a:hover {
                  color: $sad;
                }
                li:nth-child(3) a:hover {
                  color: $happy;
                }
                li:nth-child(4) a:hover {
                  color: $very-happy;
                }
              }
            }
          }
        }
      }
    }
    .icons-bar {
      padding: 17px 20px;
      background-color: white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      .list-group {
        justify-content: space-between;
        .list-group-item {
          padding: 6px 8px;
          width: 28px;
          height: 28px;
          border-radius: 24px;
          border: none;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          a {
            text-decoration: none;
            color: $gray-20;
            display: flex;
            i {
              &::before {
                font-size: 20px;
              }
            }
          }
        }
      }
      .list-group-item.selected.active {
        background: $primary-color;
        a {
          color: $white;
        }
      }
      .list-group-item.selected {
        background: $primary-color;
        a {
          color: $white;
        }
      }
      .list-group-item.active {
        background: transparent;
        a {
          color: $primary-color;
          &::before {
            color: $primary-color;
          }
        }
      }
    }
    main {
      height: 100vh;
      overflow: hidden;
    }
  
    .arrow-bar {
      padding: 20px;
      background-color: white;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      ul {
        li {
          a {
            color: $gray-30;
            i {
              &::before {
                font-size: 20px;
              }
            }
          }
          .active {
            color: $secondary-color;
          }
        }
        li:first-child {
          transform: rotate(-90deg);
        }
        li:last-child {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .indicator {
    .icons-bar {
      padding: 30px 150px;
      .list-group {
        .list-group-item {
          padding: 8px 10px;
          width: 38px;
          height: 38px;
          a {
            i {
              &::before {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
    .indicator-slider {
      display: block;
    }
    .indicator-rate {
      display: none;
    }
    .section-padding {
      position: relative;
      padding: 90px 90px;
      height: 100vh;
      .slick-slider {
        height: 100%;
        overflow: hidden;
        .main-info {
          padding: 0px 100px;
          border-radius: 41px;
          height: calc(100vh - 180px);
          margin-bottom: 90px;
          .title-bar {
            h1 {
              font-size: 35px;
              margin-bottom: 15px;
              margin-top: 15px;
            }
            p {
              font-size: 17px;
            }
            .top-icon {
              i {
                &::before {
                  font-size: 84px;
                }
              }
            }
          }
        }
      }
      main {
        height: 100%;
        overflow: hidden;
      }
    }

    .arrow-bar {
      padding: 30px;
      ul {
        li {
          a {
            i {
              &::before {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .indicator {
    .icons-bar {
      width: 130px;
      padding: 0;
      height: 100vh;
      display: flex;
      justify-content: center;
      padding-top: 80px;
      padding-bottom: 80px;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 2;

      .list-group {
        align-items: center;
      }
    }
    .indicator-rate {
      padding-top: 5px;
      display: block;
    }
    .indicator-slider {
      display: none;
    }
    .section-padding {
      width: 100%;
      padding: 0 130px;
      background-color: $white;
    }
    .main-info {
      padding: 120px;
      margin-top: 90px;
      .title-bar {
        width: 350px;
        margin: 0;

        .top-icon {
          i {
            &::before {
              font-size: 104px;
            }
          }
        }
      }
      .slider-progress-bar {
        width: 100%;
        height: 100%;
        margin: 0;
        display: inline-grid;
        align-items: center;
        label {
          font-size: 18px;
        }
      }
    }
    .arrow-bar {
      top: 0;
      left: auto;
      display: flex;
      padding: 35px 47px;
      ul {
        li {
          a {
            i {
              &::before {
                font-size: 35px;
              }
            }
          }
        }
        li:first-child {
          transform: rotate(0deg);
        }
        li:last-child {
          transform: rotate(0deg);
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .indicator .main-info {
    padding: 120px;
  }
}
.three-squares {
  .ant-slider-track {
    border: 3px solid #fd6d22;
  }
}
.six-squares {
  .ant-slider-track {
    border: 3px solid #ffb536;
  }
}
.nine-squares {
  .ant-slider-track {
    border: 3px solid #a4d900;
  }
}
.all-squares {
  .ant-slider-track {
    border: 3px solid #00cf00;
  }
}
