@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?7wo6wn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?7wo6wn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?7wo6wn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close-icon {
  &:before {
    content: $icon-close-icon; 
  }
}
.icon-save-icon {
  &:before {
    content: $icon-save-icon; 
  }
}
.icon-cancel-icon {
  &:before {
    content: $icon-cancel-icon; 
  }
}
.icon-mission-icon {
  &:before {
    content: $icon-mission-icon; 
  }
}
.icon-warn-icon {
  &:before {
    content: $icon-warn-icon; 
  }
}
.icon-minus-icon {
  &:before {
    content: $icon-minus-icon; 
  }
}
.icon-plus-icon {
  &:before {
    content: $icon-plus-icon; 
  }
}
.icon-feed-ok-icon {
  &:before {
    content: $icon-feed-ok-icon; 
  }
}
.icon-feed-bad-icon {
  &:before {
    content: $icon-feed-bad-icon; 
  }
}
.icon-print-icon {
  &:before {
    content: $icon-print-icon; 
  }
}
.icon-link-icon {
  &:before {
    content: $icon-link-icon; 
  }
}
.icon-pdf-icon .path1 {
  &:before {
    content: $icon-pdf-icon-path1;  
    color: rgb(0, 0, 0);
  }
}
.icon-pdf-icon .path2 {
  &:before {
    content: $icon-pdf-icon-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-pdf-icon .path3 {
  &:before {
    content: $icon-pdf-icon-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-pdf-icon .path4 {
  &:before {
    content: $icon-pdf-icon-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.icon-pdf-icon .path5 {
  &:before {
    content: $icon-pdf-icon-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.icon-Recurso-31-icon {
  &:before {
    content: $icon-Recurso-31-icon; 
  }
}
.icon-very-happy-icon {
  &:before {
    content: $icon-very-happy-icon; 
  }
}
.icon-happy-icon {
  &:before {
    content: $icon-happy-icon; 
  }
}
.icon-sad-icon {
  &:before {
    content: $icon-sad-icon; 
  }
}
.icon-very-sad-icon {
  &:before {
    content: $icon-very-sad-icon; 
  }
}
.icon-very-happy-stroke-icon {
  &:before {
    content: $icon-very-happy-stroke-icon; 
  }
}
.icon-happy-stroke-icon {
  &:before {
    content: $icon-happy-stroke-icon; 
  }
}
.icon-sad-stroke-icon {
  &:before {
    content: $icon-sad-stroke-icon; 
  }
}
.icon-very-sad-stroke-icon {
  &:before {
    content: $icon-very-sad-stroke-icon; 
  }
}
.icon-move-icon {
  &:before {
    content: $icon-move-icon; 
  }
}
.icon-show-icon {
  &:before {
    content: $icon-show-icon; 
  }
}
.icon-arrow-icon {
  &:before {
    content: $icon-arrow-icon; 
  }
}
.icon-comment-stroke-icon {
  &:before {
    content: $icon-comment-stroke-icon; 
  }
}
.icon-comment-icon {
  &:before {
    content: $icon-comment-icon; 
  }
}
.icon-arrow-down-icon {
  &:before {
    content: $icon-arrow-down-icon; 
  }
}
.icon-arrow-up-icon {
  &:before {
    content: $icon-arrow-up-icon; 
  }
}
.icon-learning-icon {
  &:before {
    content: $icon-learning-icon; 
  }
}
.icon-company-icon {
  &:before {
    content: $icon-company-icon; 
  }
}
.icon-organization-icon {
  &:before {
    content: $icon-organization-icon; 
  }
}
.icon-values-icon {
  &:before {
    content: $icon-values-icon; 
  }
}
.icon-influence-icon {
  &:before {
    content: $icon-influence-icon; 
  }
}
.icon-peace-icon {
  &:before {
    content: $icon-peace-icon; 
  }
}
.icon-time-icon {
  &:before {
    content: $icon-time-icon; 
  }
}
.icon-challenge-icon {
  &:before {
    content: $icon-challenge-icon; 
  }
}
.icon-team-icon {
  &:before {
    content: $icon-team-icon; 
  }
}
.icon-money-icon {
  &:before {
    content: $icon-money-icon; 
  }
}
.icon-appreciation-icon {
  &:before {
    content: $icon-appreciation-icon; 
  }
}
.icon-autonomy-icon {
  &:before {
    content: $icon-autonomy-icon; 
  }
}

