@import './assets/sass/styles.scss';

.AppLayout {
  &__mock {
    background-color: $circle-ok;
    color: $white;
    width: fit-content;
    position: absolute;
    top: 0;
    right: 0;
  }
}
