/***********
Global Button
***********/

@import "./variables.scss";

.btn-primary {
  display: inline-flex;
  align-items: center;
  background-color: $primary-color !important;
  width: 190px;
  justify-content: center;
  gap: 6px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: $primary-color !important;
  }
  &:focus {
    box-shadow: none;
    background-color: $disable-btn !important;
  }
  &:active:focus {
    box-shadow: none;
    background-color: $disable-btn !important;
  }
  i {
    &::before {
      font-size: 20px;
      position: relative;
    }
  }
}
.btn-light {
  width: 100%;
  display: inline-flex;
  align-items: center;
  background-color: $gray-40 !important;
  justify-content: center;
  gap: 6px;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  color: $white;
  &:hover {
    background-color: $gray-40 !important;
    color: $white;
  }
  &:focus {
    box-shadow: none;
  }
  i {
    &::before {
      font-size: 20px;
      position: relative;
    }
  }
}

.btn-secondary {
  display: inline-flex;
  align-items: center;
  background-color: $secondary-color !important;
  width: 100%;
  justify-content: center;
  gap: 6px;
  padding: 5px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: $secondary-color !important;
  }
  &:focus {
    box-shadow: none;
  }
  i {
    &::before {
      font-size: 20px;
    }
  }
}
.disabled-btn {
  background-color: $disable-btn !important;
  &:hover {
    background-color: $disable-btn !important;
  }
  &:active:focus {
    box-shadow: none;
    background-color: $disable-btn !important;
  }
}

@media (min-width: 768px) {
  .btn-primary,
  .btn-secondary,
  .btn-light {
    width: 255px;
    gap: 8px;
    padding: 6px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 7px;
    i {
      &::before {
        font-size: 26px;
        top: 1px;
      }
    }
  }
}

/*Modal Start*/
.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal.commentary-model {
      top: 0;
      height: 100vh;
      display: flex;
      margin: 0 auto;
      padding-bottom: 0;
      .ant-modal-content {
        height: fit-content;
        margin: auto;
        background-color: #e4e4e6;
        border-radius: 24px;
        .ant-modal-close {
          width: 27px;
          height: 27px;
          background-color: $white;
          border-radius: 100px;
          color: #66666a;
          top: 20px;
          right: 24px;
          .ant-modal-close-x {
            width: 100%;
            height: auto;
            line-height: 1;
            position: relative;
            top: -1px;
          }
        }
        .ant-modal-footer {
          display: none;
        }
        .ant-modal-body {
          padding: 30px !important;
          padding-bottom: 60px !important;
          border-radius: 24px;
          background: $white;
          h2 {
            font-size: 22px;
          }
          p {
            font-size: 16px;
            font-weight: 600;
            color: $text-color;
          }
          form {
            .form-control {
              padding: 20px;
              border-radius: 8px;
              border: none;
              background-color: #f8f8f9;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .ant-modal-root {
    .ant-modal-wrap {
      .ant-modal.commentary-model {
        width: 680px !important;
        .ant-modal-content {
          .ant-modal-body {
            padding: 60px !important;

            h2 {
              font-size: 24px;
              i {
                &::before {
                  font-size: 28px;
                }
              }
            }
            p {
              font-size: 18px;
            }
            form {
              .form-control {
                border-radius: 10px;
              }
            }
            .btn-primary,
            .btn-secondary,
            .btn-light {
              width: 170px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .ant-modal-root {
    .ant-modal-wrap {
      .ant-modal.commentary-model {
        width: 680px !important;
        .ant-modal-content {
          .ant-modal-body {
            padding: 60px !important;
            background-color: #f4f4f5;

            h2 {
              font-size: 24px;
              i {
                &::before {
                  font-size: 28px;
                }
              }
            }
            p {
              font-size: 18px;
            }
            form {
              .form-control {
                border-radius: 8px;
                background-color: $white;
              }
            }
            .btn-primary,
            .btn-secondary,
            .btn-light {
              width: 170px;
            }
          }
        }
      }
    }
  }
}
/*Modal End */

/*Close Modal Start*/
.ant-modal-root {
  .ant-modal-wrap {
    .ant-modal.closemodel {
      top: 0;
      height: 100vh;
      display: flex;
      margin: 0 auto;
      padding-bottom: 0;
      .ant-modal-content {
        width: 100%;
        text-align: center;
        border-radius: 24px;
        background-color: $white;
        height: fit-content;
        margin: auto;
        .ant-modal-close {
          width: 27px;
          height: 27px;
          background-color: $white;
          border-radius: 100px;
          color: #66666a;
          top: 20px;
          right: 24px;
          .ant-modal-close-x {
            width: 100%;
            height: auto;
            line-height: 1;
            position: relative;
            top: -1px;
          }
        }
        .ant-modal-body {
          padding: 30px 30px 50px;
          h2 {
            font-size: 20px;
          }
          p {
            font-size: 16px;
            font-weight: 500;
            color: $text-color;
          }
          .model-buttons {
            margin-top: 70px;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .ant-modal-root {
    .ant-modal-wrap {
      .ant-modal.closemodel {
        width: 680px !important;
        .ant-modal-content {
          .ant-modal-body {
            padding: 50px 50px 60px;
            .model-buttons {
              .btn-light,
              .btn-primary {
                width: 164px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .ant-modal-root {
    .ant-modal-wrap {
      .ant-modal.closemodel {
        width: 800px !important;
        .ant-modal-content {
          background-color: #f4f4f5;
          .ant-modal-body {
            padding: 50px 50px 60px;
            text-align: center !important;
            h2 {
              font-size: 26px;
            }
            p {
              font-size: 17px;
            }
          }
        }
      }
    }
  }
}
/*Close Modal End*/

/*Range slider For desktop*/
.ant-rate {
  width: 100%;
  display: flex;
  .ant-rate-star {
    flex: 1;
    margin: 0;
  }
  .ant-rate-star-first {
    display: none;
  }
  .ant-rate-star-second .anticon {
    display: none;
  }
  .ant-rate-star-second {
    width: 100%;
    border-radius: 100px;
    background-color: #cdcdce;
    border: 1px solid #f8f8f9;
    height: 10px;
  }
  div:hover {
    transform: none;
  }
}
.three-squares {
  .ant-rate-star-full {
    .ant-rate-star-second {
      background-color: #fd6d22;
    }
  }
}
.six-squares {
  .ant-rate-star-full {
    .ant-rate-star-second {
      background-color: #ffb536;
    }
  }
}
.nine-squares {
  .ant-rate-star-full {
    .ant-rate-star-second {
      background-color: #a4d900;
    }
  }
}
.all-squares {
  .ant-rate-star-full {
    .ant-rate-star-second {
      background-color: #00cf00;
    }
  }
}

/*Range slider For Mobile*/
.ant-slider {
  .ant-slider-mark {
    display: none;
  }
  .ant-slider-step {
    height: 6px;
    .ant-slider-dot {
      border: 0;
      top: 0;
      width: 2px;
      height: 100%;
    }
  }
  .ant-tooltip {
    display: none;
  }
}
