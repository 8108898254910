@import "../../assets/sass/variables.scss";

.result-main {
  padding: 60px 45px 0;
  position: relative;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .top-heading-strip {
    padding: 7px;
    background: #000;
    width: auto;
    text-align: center;
    border-radius: 0 0 12px 12px;
    position: fixed;
    top: 0;
    left: 45px;
    right: 45px;
    z-index: 10;
    h1 {
      font-size: 18px;
      margin: 0;
      color: $white;
    }
  }
  .title-bar {
    margin-top: 20px;
    margin-bottom: 20px;
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }

  .accordion {
    padding: 0;
    padding-bottom: 126px;
    .accordion-print {
      display: none;
    }
    .accordion-title {
      margin-top: 30px;
      margin-bottom: 20px;
      h2 {
        font-size: 18px;
        color: $text-color;
        margin: 0;
      }
      p {
        font-size: 16px;
      }
    }
    .ant-collapse {
      margin: 0 -45px;
      background-color: $white;
      .ant-collapse-item {
        border-bottom-color: $gray-10;
        .ant-collapse-header {
          padding: 20px 70px 20px 45px;
          font-size: 20px;
          font-weight: 500;
          align-items: center;
          .ant-collapse-expand-icon {
            position: absolute;
            right: 10px;
            line-height: 0;
          }
          .ant-collapse-header-text {
            width: 100%;
          }
          h2 {
            width: 100%;
            font-size: 19px;
          }
          h3 {
            font-size: 26px;
          }
          h4 {
            font-size: 19px;
            color: #aaaaae;
          }
          .ant-progress {
            display: none !important;
          }
          .ant-collapse-arrow {
            right: 40px;
          }
          i {
            &::before {
              font-size: 22px;
            }
          }
        }
        .ant-collapse-content {
          // padding: 0 30px 30px;
          .ant-collapse-content-box {
            background-color: $white;
            padding: 0;
            .content-box-info {
              padding: 20px;
              background-color: $gray-05;
              border-radius: 10px;
              margin-bottom: 20px;
              h3 {
                font-size: 16px;
                font-weight: 600;
              }
              p {
                font-size: 16px;
                color: $text-color;
              }
              li {
                font-size: 16px;
                color: $text-color;
              }
            }
          }
        }
      }
      .ant-collapse-item:first-child {
        border-top: 1px solid $gray-10;
      }
      .ant-collapse-item:last-child {
        border-bottom: 1px solid $gray-10;
      }
    }
  }
  .teamitemcallout {
    padding: 0 10px;
    border-left: 2px solid;
    .callout-title {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
      h4 {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
    }
  }
  .callout {
    padding: 0 10px;
    border-left: 2px solid;
    margin-bottom: 24px;
    .callout-title {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
      h4 {
        font-size: 14px;
        margin: 0;
      }
    }
    p {
      margin: 0;
    }
  }
  .callout-warning {
    border-left-color: $warning;
    i {
      &::before {
        font-size: 16px;
        color: $very-sad;
      }
    }
  }
  .callout-info {
    border-left-color: $info;
    i {
      &::before {
        font-size: 16px;
        color: #1299ff;
      }
    }
  }

  .ant-progress.ant-progress-circle {
    margin: 30px auto;
    .ant-progress-inner {
      width: 230px !important;
      height: 230px !important;
      .ant-progress-circle-path {
        color: $primary-color;
        stroke: $primary-color;
      }
    }
    .ant-progress-text {
      h1 {
        font-size: 84px;
        margin: 0;
        line-height: 1;
      }
      h2 {
        font-size: 24px;
        color: #aaaaae;
        margin: 0;
        line-height: 1;
      }
    }
  }
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  .copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    gap: 10px;
    svg {
      width: 46px;
    }
    p {
      font-size: 12px;
      margin: 0;
    }
  }
}

.links-mobile {
  padding: 20px 0;
  background: #fff;
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  a {
    text-decoration: none;
    color: $secondary-color;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    gap: 5px;
    i {
      &::before {
        font-size: 22px;
      }
    }
  }
  .icon-pdf-icon:before {
    content: "\e90b";
  }
}

.up100 {
  h3 {
    color: $green;
  }
}
.up74 {
  h3 {
    color: $light-green;
  }
}
.up49 {
  h3 {
    color: $orange;
  }
}
.up24 {
  h3 {
    color: $red;
  }
}

@media (min-width: 768px) {
  .result-main {
    padding-left: 70px;
    padding-right: 70px;
    .header {
      width: auto;
      display: flex;
      justify-content: space-between;
      position: fixed;
      top: 0;
      right: 70px;
      left: 70px;
      background-color: #fff;
      z-index: 10;
    }
    .accordion {
      padding-bottom: 65px;
      .ant-collapse {
        margin: 0;
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 20px 60px 20px 20px;
            .ant-collapse-arrow {
              right: 20px;
            }
            .ant-progress {
              display: block !important;
              width: 50%;
              .ant-progress-outer {
                .ant-progress-inner {
                  height: 9.5px;
                  background-color: #ededed;
                  .ant-progress-bg {
                    height: 9.5px !important;
                  }
                }
              }
            }
            h2 {
              width: 60%;
            }
          }
        }
      }
    }
    .top-heading-strip {
      width: 226px;
      left: auto;
      right: auto;
      h1 {
        font-size: 18px;
        margin: 0;
        color: $white;
      }
    }
    .right-links {
      padding: 20px 0;
      background: #fff;
      display: flex;
      gap: 5px;
      justify-content: right;
      a {
        text-decoration: none;
        color: $secondary-color;
        display: inline-flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        gap: 5px;
        i {
          &::before {
            font-size: 22px;
          }
        }
      }
      .icon-pdf-icon:before {
        content: "\e90b";
      }
    }
    .ant-progress.ant-progress-circle {
      .ant-progress-inner {
        width: 220px !important;
        height: 220px !important;
      }
      .ant-progress-text {
        h1 {
          font-size: 80px;
        }
        h2 {
          font-size: 20px;
        }
      }
    }
  }

  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    .copyright {
      justify-content: center;
      svg {
        width: 46px;
      }
      p {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .result-main {
    padding-left: 70px;
    padding-right: 70px;
  }
  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .result-main {
    padding-left: 70px;
    padding-right: 70px;
    .accordion {
      padding: 0 0 0 80px;
      margin-top: 0px;
      margin-bottom: 0px;
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 14px 60px 14px 20px;
          }
        }
      }
    }
    .ant-progress.ant-progress-circle {
      .ant-progress-inner {
        width: 200px !important;
        height: 200px !important;
      }
      .ant-progress-text {
        h1 {
          font-size: 75px;
        }
      }
    }
  }
  .footer {
    width: 30%;
    .copyright {
      justify-content: left;
    }
  }
}
@media (min-width: 1400px) {
  .result-main {
    padding-left: 130px;
    padding-right: 130px;
    .header {
      left: 130px;
      right: 130px;
    }
    .accordion {
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            padding: 20px 60px 20px 20px;
          }
        }
      }
    }
  }
}

.download {
  width: 1080px !important;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  .result-main {
    padding-top: 20px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
    .header {
      display: none;
    }
    .ant-col-xl-7,
    .ant-col-xl-17 {
      width: 100% !important;
      flex: 0 0 100%;
      display: block;
    }
    .print-col-30 {
      display: block;
      flex: 0 0 30%;
      max-width: 30%;
      margin: 0;
      padding: 0;
      text-align: center !important;
      .ant-progress {
        .ant-progress-inner {
          width: 170px !important;
          height: 170px !important;
        }
        .ant-progress-text {
          h1 {
            font-size: 50px;
          }
          h2 {
            font-size: 16px;
          }
        }
      }
    }
    .print-col-70 {
      display: block;
      flex: 0 0 70%;
      max-width: 70%;
      margin: 0;
      padding: 0;
    }
    .accordion {
      padding: 0;
      margin-top: 20px;
      .accordion-title {
        margin-top: 0;
        margin-bottom: 0;
      }
      .accordion-print {
        display: block !important;
      }
      .accordion-default {
        display: none;
      }
      .ant-collapse-item {
        margin-bottom: 15px;
        .ant-collapse-header {
          padding: 15px 60px 15px 20px;
        }
      }
    }
  }
  .feed {
    display: none !important;
  }
  .links-mobile {
    position: relative;
    display: none !important;
  }
  .footer {
    display: none;
  }
}

@media only screen and (min-width: 320px) {
  .download {
    .result-main {
      .ant-col-xl-7,
      .ant-col-xl-17 {
        width: auto !important;
        display: block !important;
        padding-left: 33px !important;
        align-self: center !important;
        padding-right: 33px !important;
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .download {
    .result-main {
      .ant-col-xl-7,
      .ant-col-xl-17 {
        width: auto !important;
        display: block !important;
        padding-left: 30px !important;
        align-self: center !important;
        padding-right: 30px !important;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .download {
    .result-main {
      .ant-col-xl-7,
      .ant-col-xl-17 {
        width: auto !important;
        display: block !important;
        padding-left: 0px !important;
        align-self: center !important;
        padding-right: 0px !important;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .download {
    .result-main {
      .ant-col-xl-7,
      .ant-col-xl-17 {
        padding-left: 0px !important;
        padding-right: 0px !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        display: block !important;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .download {
    .result-main {
      .ant-col-xl-7,
      .ant-col-xl-17 {
        padding-left: 0px !important;
        padding-right: 0px !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        display: block !important;
      }
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  @page :first {
    margin-top: 0;
  }
  .result-main {
    padding-top: 20px;
    .header {
      display: none;
    }
    .print-col-30 {
      display: block;
      flex: 0 0 30%;
      max-width: 30%;
      margin: 0;
      padding: 0;
      text-align: left !important;
      .ant-progress {
        .ant-progress-inner {
          width: 170px !important;
          height: 170px !important;
        }
        .ant-progress-text {
          h1 {
            font-size: 50px;
          }
          h2 {
            font-size: 16px;
          }
        }
      }
    }
    .print-col-70 {
      display: block;
      flex: 0 0 70%;
      max-width: 70%;
      margin: 0;
      padding: 0;
    }
    .accordion {
      padding-bottom: 0;
      margin-top: 20px;
      .accordion-title {
        margin-top: 0;
      }
      .accordion-print {
        display: block !important;
      }
      .accordion-default {
        display: none;
      }
      .ant-collapse-item {
        margin-bottom: 15px;
        .ant-collapse-header {
          padding: 15px 60px 15px 20px;
        }
      }
    }
  }
  .feed {
    display: none !important;
  }
  .links-mobile {
    position: relative;
    display: none !important;
  }
  .footer {
    display: none;
  }
}
