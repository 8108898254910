// Icon
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icons" !default;

$icon-close-icon: "\e900";
$icon-save-icon: "\e901";
$icon-cancel-icon: "\e902";
$icon-mission-icon: "\e903";
$icon-warn-icon: "\e904";
$icon-minus-icon: "\e905";
$icon-plus-icon: "\e906";
$icon-feed-ok-icon: "\e907";
$icon-feed-bad-icon: "\e908";
$icon-print-icon: "\e909";
$icon-link-icon: "\e90a";
$icon-pdf-icon-path1: "\e90b";
$icon-pdf-icon-path2: "\e90c";
$icon-pdf-icon-path3: "\e90d";
$icon-pdf-icon-path4: "\e90e";
$icon-pdf-icon-path5: "\e90f";
$icon-Recurso-31-icon: "\e910";
$icon-very-happy-icon: "\e911";
$icon-happy-icon: "\e912";
$icon-sad-icon: "\e913";
$icon-very-sad-icon: "\e914";
$icon-very-happy-stroke-icon: "\e915";
$icon-happy-stroke-icon: "\e916";
$icon-sad-stroke-icon: "\e917";
$icon-very-sad-stroke-icon: "\e918";
$icon-move-icon: "\e919";
$icon-show-icon: "\e91a";
$icon-arrow-icon: "\e91b";
$icon-comment-stroke-icon: "\e91c";
$icon-comment-icon: "\e91d";
$icon-arrow-down-icon: "\e91e";
$icon-arrow-up-icon: "\e91f";
$icon-learning-icon: "\e920";
$icon-company-icon: "\e921";
$icon-organization-icon: "\e922";
$icon-values-icon: "\e923";
$icon-influence-icon: "\e924";
$icon-peace-icon: "\e925";
$icon-time-icon: "\e926";
$icon-challenge-icon: "\e927";
$icon-team-icon: "\e928";
$icon-money-icon: "\e929";
$icon-appreciation-icon: "\e92a";
$icon-autonomy-icon: "\e92b";

$primary-color: #18ff18;
$dark: #191818;

$light-grey1: #868d96;
$light-grey2: #faf8ff;
$light-grey3: #fafafa;
$light-grey4: #707070;
$light-grey5: #eaecf5;
$light-grey6: #f7f7f7;
$white: #ffffff;
$dark-grey1: #3d3d3d;
$circle-high: #f63123;
$circle-ok: #2eac33;
$high-alert: #ff3324b3;
$high-alert1: #ff3324;
$medium-alert: #ff9d00b3;
$medium-alert1: #ff9300;
$low-alert: #0596fdb3;
$low-alert1: #0596fd;
$ok-alert: #2eac33b3;
$none-alert: #8488a0;
$onTrack-alert: #3a31f1;
$disable-alert: #9f9f9f;
$border-color: #e0e0e0;
$body-background: #f8f8fa;
$backgroundForm: rgba(0, 0, 0, 0.5);
$boxShadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$unitWhite: #f2f2f2;

$primary-color: #4bef0f;
$secondary-color: #28282d;
$heading-color: #28282d;
$text-color: #66666a;
$bg-gray-10: #f8f8f9;
$disable-btn: #cdcdce;
$gray-05: #f9fafa;
$gray-10: #d6d6d7;
$gray-20: #e2e2e3;
$gray-30: #cdcdce;
$gray-40: #c0c0c2;
$gray-50: #545458;
$very-sad: #ed1d24;
$sad: #ff8920;
$happy: #a4d900;
$very-happy: #4fdc10;
$warning: #fbbfbf;
$info: #bce6ff;
$green: #00cf00;
$light-green: #a4d900;
$orange: #ffb536;
$red: #e82702;
