@import "../../assets/sass/variables.scss";

.feed {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  label {
    font-size: 14px;
  }
  a {
    text-decoration: none;
    color: $gray-30;
    display: flex;
    i {
      &::before {
        font-size: 22px;
      }
    }
  }
  a.yes {
    &:hover {
      color: $very-happy;
    }
  }
  a.no {
    &:hover {
      color: $very-sad;
    }
  }
  a.activeyes {
    color: $very-happy;
  }
  a.activeno {
    color: $very-sad;
  }
}
