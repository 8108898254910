@import "../../assets/sass/variables.scss";

* {
  font-family: "Space Grotesk";
}
.multidemographic {
  height: 100%;
  display: contents;
  .outer-padding {
    padding: 0;
    height: 100%;
    display: flex;
    .inside-padding {
      width: 100%;
      padding: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      .content-area {
        .title {
          margin-bottom: 50px;
          h1 {
            font-size: 27px;
            color: $heading-color;
          }
          p {
            font-size: 16px;
            color: $text-color;
          }
        }
        .select-category {
          margin-bottom: 30px;
          .btn-dropdown {
            width: 290px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .multidemographic {
    display: block;
    .outer-padding {
      height: 100vh;
      padding: 0px 60px 60px;
      background-color: $bg-gray-10;
      .inside-padding {
        padding: 50px;
        background-color: white;
        border-radius: 0 0 15px 15px;
        .content-area {
          .title {
            width: 440px;
            margin-bottom: 50px;
            h1 {
              font-size: 35px;
            }
            p {
              font-size: 17px;
            }
          }
          .select-category {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .multidemographic {
    display: block;
    .outer-padding {
      height: 100%;
      padding: 60px 60px 60px 0px;
      display: block;
      .inside-padding {
        height: inherit;
        padding: 100px;
        border-radius: 0 15px 15px 0;
        .content-area {
          .select-category {
            .btn-dropdown {
              width: 360px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .multidemographic {
    height: 100vh;
  }
}
