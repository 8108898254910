@import "../../assets/sass/variables.scss";

.select-category {
  margin-bottom: 44px;
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
  .required {
    color: red;
  }
  .btn-dropdown {
    width: 100%;
    background-color: $secondary-color !important;
    color: $white !important;
    padding: 2px 14px;
    font-size: 15px !important;
    line-height: 2;
    border-radius: 5px;
    i {
      &::before {
        font-size: 12px;
        position: relative;
        top: 1px;
      }
    }
  }
}

@media (min-width: 768px) {
  .select-category {
    margin-bottom: 230px;
    h2 {
      font-size: 18px;
    }
    .btn-dropdown {
      width: 270px;
      font-size: 17px;
      border-radius: 7px;
      i {
        &::before {
          font-size: 16px;
          top: 2px;
        }
      }
      .ant-dropdown-menu-item {
        padding: 10px 20px;
        font-size: 17px;
      }
    }
  }
}
@media (min-width: 992px) {
  .select-category {
    margin-bottom: 80px;
    .btn-dropdown {
      width: 100%;
    }
  }
}
